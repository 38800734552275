import styled from 'styled-components';
import { prop } from 'styled-tools';
import pdfIcon from '../images/icon-pdf.svg'

const DocumentList = styled.ul`
  font-family: ${prop('theme.fonts.default')};
  list-style: none;
  margin-bottom: 1.25rem;
  padding-left: 0;

  &&& {
    a {
      padding-left: 1.5625rem;
      background: url(${pdfIcon}) no-repeat;
      color: ${prop('theme.colors.black')};
      font-size: 0.875rem;
      font-weight: 700;

      &:hover {
        color: ${prop('theme.colors.primaryRed')};
      }
    }
  }

  li {
    margin-bottom: 1rem;
  }
`;

export default DocumentList
