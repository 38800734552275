import React from 'react'
import styled, { css } from 'styled-components'
import { ifProp, prop } from 'styled-tools'
import { Row, Col } from 'reactstrap'
import DocumentList from 'components/DocumentList'
import Layout from '../components/Layout'
import SectionsWrapper from '../components/SectionsWrapper'
import { useLayoutContext } from 'context/LayoutContext';
import Section from '../components/Section'
import Headline from '../components/Headline'
import SEO from '../components/SEO'

const forms = [
  { name: '407 Approval Letter Form', href: 'https://assets.tastyworks.com/production/documents/407_approval_letter_form.pdf' },
  { name: 'ACATS Form', href: 'https://assets.tastyworks.com/production/documents/acat_form.pdf' },
  { name: 'Entity Beneficial Owner Form', href: 'https://assets.tastyworks.com/production/documents/entity_beneficial_owner_form.pdf' },
  { name: 'Individual Beneficial Owner Form', href: 'https://assets.tastyworks.com/production/documents/individual_beneficial_owner_form.pdf' },
  { name: 'Internal Transfer Request', href: 'https://assets.tastyworks.com/production/documents/internal_transfer_request.pdf' },
  { name: 'Internal Transfer To Ira Request', href: 'https://assets.tastyworks.com/production/documents/internal_transfer_to_ira_request.pdf' },
  { name: 'IRA Change of Beneficiary Designation', href: 'https://assets.tastyworks.com/production/documents/ira_change_of_beneficiary_form.pdf' },
  { name: 'IRA Deposit Slip', href: 'https://assets.tastyworks.com/production/documents/ira_deposit_slip.pdf' },
  { name: 'IRA Designation of Beneficiary', href: 'https://assets.tastyworks.com/production/documents/designation_of_beneficiary_form.pdf' },
  { name: 'IRA Distribution Request', href: 'https://assets.tastyworks.com/production/documents/ira_distribution_request.pdf' },
  { name: 'Large Trader Disclosure Form', href: 'https://assets.tastyworks.com/production/documents/large_trader_disclosure_form.pdf' },
  { name: 'Limited Trading Authorization-LTA', href: 'https://assets.tastyworks.com/production/documents/limited_trading_authorization.pdf' },
  { name: 'Name Change Authorization', href: 'https://assets.tastyworks.com/production/documents/name_change_authorization.pdf' },
  { name: 'Required Minimum Distribution Form', href: 'https://assets.tastyworks.com/production/documents/required_minimum_distribution_form.pdf' },
  { name: 'Roth IRA Conversion Request', href: 'https://assets.tastyworks.com/production/documents/roth_ira_conversion_form.pdf' },
  { name: 'Roth IRA Distribution Request', href: 'https://assets.tastyworks.com/production/documents/roth_ira_distribution_request.pdf' },
  { name: 'Transfer on Death', href: 'https://assets.tastyworks.com/production/documents/transfer_on_death_form.pdf' },
  { name: 'Trust Beneficiary Form', href: 'https://assets.tastyworks.com/production/documents/trust_beneficiary_form.pdf' },
  { name: 'Trust Grantor Form', href: 'https://assets.tastyworks.com/production/documents/trust_grantor_form.pdf' }
]
const agreements = [
  { name: 'Apex Customer Agreement', href: 'https://assets.tastyworks.com/production/documents/clearing_customer_agreement.pdf' },
  { name: 'Apex Futures & Exchange-Traded Options Customer Agreement ', href: 'https://assets.tastyworks.com/production/documents/clearing_futures_exchange_traded_options_customer_agreement.pdf' },
  { name: 'CME Group Professional Subscription Agreement', href: 'https://assets.tastyworks.com/production/documents/cme_professional_market_data_subscriber_agreement.pdf' },
  { name: 'CME Group Subscription Agreement', href: 'https://assets.tastyworks.com/production/documents/cme_market_data_subscriber_agreement.pdf' },
  { name: 'Corporate Account Agreement', href: 'https://assets.tastyworks.com/production/documents/clearing_corporate_account_agreement.pdf' },
  { name: 'Corporate Cash Account Agreement', href: 'https://assets.tastyworks.com/production/documents/clearing_corporate_cash_account_agreement.pdf' },
  { name: 'End User License Agreement', href: 'https://assets.tastyworks.com/production/documents/broker_end_user_license_agreement.pdf' },
  { name: 'IRA Limited Margin Agreement', href: 'https://assets.tastyworks.com/production/documents/ira_limited_margin_agreement.pdf' },
  { name: 'IRA Limited Margin with Futures Agreement', href: 'https://assets.tastyworks.com/production/documents/ira_limited_margin_with_futures_agreement.pdf' },
  { name: 'Joint Tenants in Common Agreement', href: 'https://assets.tastyworks.com/production/documents/joint_tenants_in_common_agreement.pdf'},
  { name: 'Joint With Rights of Survivorship Agreement', href: 'https://assets.tastyworks.com/production/documents/joint_wros_agreement.pdf' },
  { name: 'Limited Liability Company Agreement', href: 'https://assets.tastyworks.com/production/documents/clearing_llc_agreement.pdf'},
  { name: 'Margin Agreement', href: 'https://assets.tastyworks.com/production/documents/margin_agreement.pdf'},
  { name: 'NASDAQ Professional Subscriber Agreement', href: 'https://assets.tastyworks.com/production/documents/nasdaq_professional_market_data_subscriber_agreement.pdf' },
  { name: 'NASDAQ Subscriber Agreement', href: 'https://assets.tastyworks.com/production/documents/nasdaq_market_data_subscriber_agreement.pdf' },
  { name: 'NYSE Professional Subscriber Addendum', href: 'https://assets.tastyworks.com/production/documents/nyse_professional_subscriber_addendum.pdf' },
  { name: 'NYSE Professional Subscriber Agreement', href: 'https://assets.tastyworks.com/production/documents/nyse_professional_market_data_subscriber_agreement.pdf' },
  { name: 'NYSE Subscriber Agreement', href: 'https://assets.tastyworks.com/production/documents/nyse_market_data_subscriber_agreement.pdf' },
  { name: 'OPRA Professional Subscriber Agreement', href: 'https://assets.tastyworks.com/production/documents/opra_professional_market_data_subscriber_agreement.pdf' },
  { name: 'OPRA Subscriber Agreement', href: 'https://assets.tastyworks.com/production/documents/opra_market_data_subscriber_agreement.pdf' },
  { name: 'Option Agreement', href: 'https://assets.tastyworks.com/production/documents/option_agreement.pdf' },
  { name: 'Partnership Agreement', href: 'https://assets.tastyworks.com/production/documents/clearing_partnership_agreement.pdf' },
  { name: 'Portfolio Margin Agreement & Risk Disclosure', href: 'https://assets.tastyworks.com/production/documents/portfolio_margin_customer_agreement.pdf' },
  { name: 'Roth IRA Agreement', href: 'https://assets.tastyworks.com/production/documents/roth_ira_agreement.pdf' },
  { name: 'tastytrade Customer Agreement', href: 'https://assets.tastyworks.com/production/documents/broker_customer_agreement.pdf' },
  { name: 'tastytrade Futures Customer Agreement', href: 'https://assets.tastyworks.com/production/documents/broker_futures_customer_agreement.pdf' },
  { name: 'tastytrade Futures IRA Customer Agreement', href: 'https://assets.tastyworks.com/production/documents/broker_futures_ira_customer_agreement.pdf'},
  { name: 'Traditional IRA Agreement', href: 'https://assets.tastyworks.com/production/documents/traditional_ira_agreement.pdf' },
  { name: 'Trust Agreement', href: 'https://assets.tastyworks.com/production/documents/clearing_trust_agreement.pdf'}
]

const FormsAndAgreementsWrapper = styled(SectionsWrapper)`
  margin-top: 6.5625rem;
  padding-bottom: 2rem;

  ${ifProp('$showAlert', css`
    margin-top: 9.5625rem;
  `)}

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    margin-top: 10.0625rem;

    ${ifProp('$showAlert', css`
      margin-top: 13.0625rem;
    `)}
  }
`

const HeadlineSection = styled(Section)`
  ${Headline} {
    padding-bottom: 2.5rem;
    text-align: left;
  }
`

const StyledCol = styled(Col)`
  h4 {
    border-bottom: 0.0625rem solid ${prop('theme.colors.lighterGray')};
    color: ${prop('theme.colors.gray')};
    font-size: 1.25rem;
    font-weight: 600;
    text-transform: uppercase;

    @media (min-width: ${prop('theme.breakpoints.md')}) {
      font-size: 1.5rem;
    }
  }
`

function FormsAndAgreements() {
  const { state: layoutContextState } = useLayoutContext();
  const { showAlert } = layoutContextState;

  return (
    <Layout>
      <SEO
        title='Forms & Agreements'
        canonicalHref='https://tastytrade.com/commissions-and-fees'
      />
      <FormsAndAgreementsWrapper noGutters $showAlert={showAlert}>
        <HeadlineSection withPadding>
          <Headline>
            Forms & Agreements
          </Headline>
        </HeadlineSection>
        <Section withPadding>
          <Row>
            <StyledCol sm={12} md={6}>
              <h4>Forms</h4>
              <DocumentList>
                {forms.map(form => {
                  return (
                    <li key={`form_${form.name}`}>
                      <a
                        href={form.href}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {form.name}
                      </a>
                    </li>
                  )
                })}
              </DocumentList>
            </StyledCol>
            <StyledCol sm={12} md={6}>
              <h4>Agreements</h4>
              <DocumentList>
                {agreements.map(agreement => {
                  return (
                    <li key={`agreement_${agreement.name}`}>
                      <a
                        href={agreement.href}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {agreement.name}
                      </a>
                    </li>
                  )
                })}
              </DocumentList>
            </StyledCol>
          </Row>
        </Section>
      </FormsAndAgreementsWrapper>
    </Layout>
  )
}

export default FormsAndAgreements;
